import styled from 'styled-components';

export default styled.div`
  display: flex;
  margin-bottom: 26px;
  align-items: center;

  .left {
    flex: 1;
    align-self: center;
  }
  p {
    margin-bottom: 0px;
  }

  .right {
  }
`;
